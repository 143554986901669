/**
 * yyyy-MM-dd形式の文字列からDate型にする
 * timezone非依存にするために、ローカル時刻 0:00 のDateを作る
 * 注: 単に new Date("2020-01-01")とすると、GMTで0:00のDateになる
 */
export const fromDateString = (dateString: string): Date => {
  if (!isDateString(dateString)) {
    throw new Error('Invalid format');
  }

  return new Date(`${dateString}T00:00:00`);
};

/**
 * 日付を表す文字列（時刻を含まない）かどうかテストする
 */
export const isDateString = (dateString: string): boolean => {
  return /^\d\d\d\d-\d\d-\d\d$/.test(dateString);
};

export const fromDateTimeString = (dateTimeString: string): Date => {
  // 時刻情報がないものは受け付けない
  if (!/^\d\d\d\d-\d\d-\d\d[T ]\d?\d:\d\d(:\d\d)?/.test(dateTimeString)) {
    throw new Error('Invalid format');
  }
  return new Date(dateTimeString);
};
