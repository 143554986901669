import { format } from 'date-fns';
import {
  fromDateString,
  fromDateTimeString,
  isDateString,
} from './fromDateString';

export enum FormatDateOption {
  full,
  noYear,
  slash,
}

export const formatDate = (
  date: Date | string | null | undefined,
  option: FormatDateOption = FormatDateOption.full,
): string => {
  if (date == null) return '-';

  if (typeof date === 'string') {
    if (isDateString(date)) {
      date = fromDateString(date);
    } else {
      date = fromDateTimeString(date);
    }
  }

  switch (option) {
    case FormatDateOption.full:
      return format(date, 'yyyy年 M月 d日');
    case FormatDateOption.noYear:
      return format(date, 'M月 d日');
    case FormatDateOption.slash:
      return format(date, 'yyyy/MM/dd');
  }
};
