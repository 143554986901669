import { format } from 'date-fns';
import { fromDateTimeString } from './fromDateString';

export enum FormatDateTimeOption {
  full,
  noYear,
  slash,
  timeOnly,
}

export const formatDateTime = (
  date: Date | string | null | undefined,
  option: FormatDateTimeOption = FormatDateTimeOption.full,
): string => {
  if (date == null) return '-';

  if (typeof date === 'string') {
    date = fromDateTimeString(date);
  }

  switch (option) {
    case FormatDateTimeOption.full:
      return format(date, 'yyyy年 M月 d日 H:mm');
    case FormatDateTimeOption.noYear:
      return format(date, 'M月 d日 H:mm');
    case FormatDateTimeOption.slash:
      return format(date, 'yyyy/MM/dd HH:mm'); // 0-padding -> HH
    case FormatDateTimeOption.timeOnly:
      return format(date, 'H:mm');
  }
};
