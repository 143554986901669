import { format } from 'date-fns';

/**
 * システム（主にAPIでのやりとり）で使用する日付文字列を取得する。(yyyy-MM-dd)
 * nullが渡された場合はnullを返す。
 * @param date Date
 */
export const getDateString = (date: Date | null): string | null => {
  if (date == null) return null;
  return format(date, 'yyyy-MM-dd');
};
